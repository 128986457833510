import styled from 'styled-components';

const AwardsContStyle = styled.div`
	&.awardsCont {
		position: relative;

		&.black {
			background-color: var(--color2);
			padding: var(--sectionToSection) 0;

			.aw-section,
			.word {
				color: var(--color1);
			}

			.aw-blocks {
				color: var(--white);
			}
		}
	}
`;

export default AwardsContStyle;
