import { memo as Memo, useEffect, useCallback, useMemo, useRef, useState } from 'react';
import * as THREE from 'three';

//* HOC's
import withUIContext from '@context/consumerHOC/UIConsumer';

//* Styles
import CursorParticlesStyle from './style';

//* Global Vars
let scene, camera, renderer, anim, width, height;

const CursorParticles = Memo((props) => {
	//! States
	const [play, setPlay] = useState(false);

	//! Refs
	const ref = useRef();
	const contRef = useRef();

	//! Params
	const circleSize = useMemo(() => 1.5, []);
	const circlesGroupCount = useMemo(() => 2, []);
	const circleTransformSize = useMemo(() => 20, []);
	const speed = useMemo(() => 10, []);
	const camCoords = useMemo(
		() => ({
			x: 0,
			y: 0,
			z: 1000,
		}),
		[contRef]
	);

	const animation = useCallback(() => {
		//! Init New Animation
		scene = new THREE.Scene();
		const el = contRef?.current?.getBoundingClientRect();
		camera = new THREE.PerspectiveCamera(60, el.width / el.height, 1, 1000);
		camera.position.set(camCoords.x, camCoords.y, camCoords.z);
		renderer = new THREE.WebGLRenderer({ canvas: ref.current, alpha: true });

		onWindowResize();

		render();
	}, []);

	//! Creating Scene with Camera
	useEffect(() => {
		contRef.current.parentElement.addEventListener('mousemove', onMouseMove);
		window.addEventListener('resize', onWindowResize);
		window.addEventListener('scroll', onScroll);

		return () => {
			contRef.current?.parentElement && contRef.current.parentElement.removeEventListener('mousemove', onMouseMove);
			window.removeEventListener('resize', onWindowResize);
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	//! Adding Points in Scene
	const addPointsGroup = useCallback((x, y) => {
		const circleMaterial = new THREE.MeshBasicMaterial({ color: Math.random() <= 0.5 ? 0xffffff : 0x000000 });
		const group = new THREE.Group();
		const newY = y + camCoords.y;
		const geometry = new THREE.CircleGeometry(circleSize, 100);
		const circle = new THREE.Mesh(geometry, circleMaterial);
		for (let i = 0; i < circlesGroupCount; i++) {
			circle.position.set(x, newY - 15, Math.random() * 100);
			group.add(circle);
		}
		scene && scene.add(group);
	}, []);

	//! Updating Dots Position
	const updatePositions = useCallback(() => {
		let parentChildren = scene.children;
		for (let i = 0; i < parentChildren.length; i++) {
			const children = parentChildren[i].children;
			children.length < 1 && parentChildren[i].remove();
			for (let k = 0; k < children.length; k++) {
				const elP = children[k].position;
				if (Math.abs(elP.y) > height) {
					parentChildren[i].remove(children[k]);
				} else {
					let xDiff = Math.random();
					xDiff = xDiff * (xDiff < 0.7 ? circleTransformSize : -circleTransformSize);
					children[k].position.set(elP.x + xDiff, elP.y - k - speed, elP.z);
				}
			}
		}
	}, []);

	//! Scroll Listener
	const onScroll = useCallback(() => {
		const el = contRef?.current?.getBoundingClientRect();
		el && setPlay(el.y > -el.height && el.y < window.innerHeight);
	}, []);

	//! Enable/Disable Animation
	useEffect(() => {
		anim && cancelAnimationFrame(anim);
		if (play) {
			animation();
		} else if (scene) {
			for (let i = 0; i < scene.children.length; i++) {
				scene.children[i].remove();
			}
		}
	}, [play]);

	//! Render
	const render = useCallback(() => {
		updatePositions();
		renderer.render(scene, camera);
		anim = requestAnimationFrame(render);
	}, []);

	//! Updating when Screen Resizing
	const onWindowResize = useCallback(() => {
		if (contRef.current) {
			const el = contRef?.current?.getBoundingClientRect();
			width = el.width;
			height = el.height;
			updateRendererSize();
		}
	}, []);

	//! Updating Scene Size when Screen Resizing
	const updateRendererSize = useCallback(() => {
		renderer && renderer.setSize(width, height);

		if (camera) {
			camera.aspect = width / height;
			camera.updateProjectionMatrix();
		}
	}, []);

	//! On Mouse Move
	const onMouseMove = useCallback((e) => {
		const widthHalf = width / 2;
		const heightHalf = height / 2;
		const el = contRef?.current?.getBoundingClientRect();
		const coefficient = props.winHeight / height;

		const pX = (e.clientX - widthHalf) * coefficient;
		const pY = -(e.clientY - el.y - heightHalf) * coefficient;

		if (!e.target.classList.contains('crCircle') && !e.target.closest(`.crCircle`)) {
			addPointsGroup(pX, pY);
		}
	}, []);

	return (
		<CursorParticlesStyle
			ref={contRef}
			onMouseMove={onMouseMove}
			className={`cursorParticles`}>
			<canvas ref={ref} />
		</CursorParticlesStyle>
	);
});

export default withUIContext(CursorParticles, ['winHeight']);
