import styled from 'styled-components';

const ButtonStyle = styled.div`
	&.buttonCont {
		display: flex;
		justify-content: center;
		margin-top: calc(var(--sectionToSection) + var(--sp9x));

		.button {
			display: block;
			text-align: center;
			position: relative;
			background-color: transparent;
			border: 0;

			svg {
				overflow: visible;
				width: 180px;
				position: absolute;
				left: 0;
				bottom: 110%;
				overflow: visible;
			}

			&.black {
				span {
					box-shadow: inset 0px -8px var(--color1), inset 0 -0.26em var(--color2);
				}

				svg {
					left: 50%;
					transform: translateX(-50%);
					fill: var(--color2);
				}
			}

			&.white {
				svg {
					fill: var(--color1);
					left: 50%;
					transform: translateX(-50%) rotateY(180deg);
				}

				span {
					box-shadow: inset 0px -7px var(--color2), inset 0 -0.22em var(--color1);
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
			margin-top: calc(var(--sectionToSection) + var(--sp8x));

			.load-more {
				line-height: 1.3 !important;
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.button {
				&.black {
					svg {
						left: 0;
						transform: translateX(0);
						fill: var(--color2);
					}
				}

				&.white {
					svg {
						fill: var(--color1);
						left: inherit;
						transform: translateX(0) rotateY(180deg);
					}
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.button {
				&.black {
					span {
						box-shadow: inset 0px -10px var(--color1), inset 0 -0.28em var(--color2);
					}
				}

				svg {
					width: 135px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			margin-top: calc(var(--sectionToSection) + var(--sp5x));

			.button {
				&.white {
					span {
						box-shadow: inset 0px -11px var(--color2), inset 0 -0.25em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -11px var(--color1), inset 0 -0.26em var(--color2);
					}
				}

				svg {
					width: 135px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
			.button {
				&.white {
					span {
						box-shadow: inset 0px -10px var(--color2), inset 0 -0.25em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -11px var(--color1), inset 0 -0.28em var(--color2);
					}
				}

				svg {
					width: 135px;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
			.button {
				&.white {
					svg {
						right: 0;
					}
				}

				&.black {
					svg {
						left: 0;
					}
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
			.button {
				&.white {
					span {
						box-shadow: inset 0px -15px var(--color2), inset 0 -0.28em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -15px var(--color1), inset 0 -0.28em var(--color2);
					}
				}

				svg {
					width: 180px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			.button {
				&.white {
					span {
						box-shadow: inset 0px -15px var(--color2), inset 0 -0.28em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -15px var(--color1), inset 0 -0.28em var(--color2);
					}
				}

				svg {
					width: 180px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			margin-top: calc(var(--sectionToSection) + var(--sp11x));

			.button {
				&.white {
					span {
						box-shadow: inset 0px -15px var(--color2), inset 0 -0.25em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -15px var(--color1), inset 0 -0.25em var(--color2);
					}
				}

				svg {
					width: 200px;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			margin-top: calc(var(--sectionToSection) + var(--sp13x));

			.button {
				&.white {
					span {
						box-shadow: inset 0px -20px var(--color2), inset 0 -0.26em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -22px var(--color1), inset 0 -0.28em var(--color2);
					}
				}

				svg {
					width: 240px;
				}
			}
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
			.button {
				&.white {
					span {
						box-shadow: inset 0px -26px var(--color2), inset 0 -0.25em var(--color1);
					}
				}

				&.black {
					span {
						box-shadow: inset 0px -27px var(--color1), inset 0 -0.27em var(--color2);
					}
				}

				svg {
					width: 280px;
				}
			}
		}
	}

	&.otherLinkWrap {
		display: inline-block;

		.otherLink {
			border: 2px solid var(--color2);
			padding: var(--sp2-5x) var(--sp3x);
			border-radius: 100%;
			letter-spacing: 1.5px;
			text-transform: uppercase;

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				padding: var(--sp3x) var(--sp4x);
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
				padding: var(--sp3x) var(--sp5x);
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
				padding: var(--sp3x) var(--sp5x);
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
				padding: var(--sp4x) var(--sp8x);
			}
		}
	}

	&.teemformBtn {
		--teemformBtnIconSive: 55px;

		display: flex;
		flex-direction: column;
		align-items: flex-end;

		svg {
			fill: var(--color1);
			overflow: visible;
			transform: rotateY(180deg);
			width: var(--teemformBtnIconSive);
			margin-bottom: var(--sp1x);
		}

		.teemButtonForm {
			text-decoration: underline;
			background-color: transparent;
			font-style: var(--ffItalic);
			font-size: var(--pM);
			font-family: var(--fontFamily2);
			color: var(--color1);
			border: none;

			&:focus {
				border: 0;
				outline: none;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				--teemformBtnIconSive: 65px;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
				--teemformBtnIconSive: 70px;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
				--teemformBtnIconSive: 85px;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
				--teemformBtnIconSive: 95px;
			}
		}
	}

	&.buttonFormCont {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		z-index: 14;

		.buttonForm {
			font-style: var(--ffItalic);
			background-color: transparent;
			border: 0;
			color: var(--color1);

			&:focus {
				border: 0;
				outline: none;
			}

			&:hover {
				font-style: var(--ffRegular);
			}
		}
	}
`;
export default ButtonStyle;
