import { memo as Memo } from 'react';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Components
import Awards from '@components/global/Awards';
import Button from '@components/global/Button';
import HTMLContainer from '@components/global/HTMLContainer';
import PageTopSection from '@components/global/PageTopSection';
import CursorParticles from '@components/global/CursorParticles';

//* Styles
import AwardsContStyle from './style';

const AwardsCont = Memo(({ data, anime, active, type, count, parentClassName, className, link, translate }) => {
    return (
        <AwardsContStyle className={`awardsCont ${parentClassName || ''} ${type === 1 ? 'norm' : 'black'}`}>
            <HTMLContainer>
                <div className={`${className || ''}`}>
                    <PageTopSection
                        className='aw-section'
                        subtitleTag='p'
                        {...data}
                    />
                    <Awards
                        className='aw-blocks'
                        active={active}
                        type={type}
                        count={count}
                        anime={anime}
                    />
                    <Button
                        link={link}
                        customClass='load-more'
                        text={translate('weHaveMoreHere')}
                        type={type === 1 ? 1 : ''}
                        ariaLabel={translate('weHaveMoreHere')}
                    />
                </div>
            </HTMLContainer>
            <CursorParticles />
        </AwardsContStyle>
    );
});

export default withLanguageContext(AwardsCont, ['translate']);
