import styled from 'styled-components';

const AwardsStyle = styled.div`
	&.awardsItemWrap {
		&.awardsItemBlack {
			@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				margin-left: calc(var(--sp2x) * -1);
				margin-right: calc(var(--sp2x) * -1);
			}
			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
				display: flex;
				flex-flow: row wrap;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
				margin-left: auto;
				margin-right: auto;
				max-width: 86.3%;
			}

			@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
				max-width: 80%;
			}
		}
	}
`;

export default AwardsStyle;
