import { memo as Memo, useState, useMemo } from 'react';

//* Components
import AwardsArrow from '@components/global/Awards/AwardsArrow';

const AwardsWhite = Memo(({ ...item }) => {
	//! States
	const [act, setAct] = useState(false);

	const elementInner = useMemo(
		() => (
			<>
				<div className={'awardsItemCont'}>
					<div className={'awardsItemInfo1'}>
						<h4 className={'h6 awardName font-poppins font-regular'}>{item.title}</h4>

						<p className={`p-m font-poppins font-light awardsItemDescription`}>{item.description}</p>
					</div>

					<div className={'awardsItemInfo2'}>
						<p className={`p-m font-poppins font-light awardsYear`}>{item.year}</p>

						<div className={`crCircle awardsLink`}>
							<p className={'p-m font-poppins font-light'}>{item.project_url.replace(/(^\w+:|^)\/\//, '')}</p>
						</div>
					</div>
				</div>

				<div className={'awardsItemWrapIcon'}>
					<AwardsArrow active={act} />
				</div>
			</>
		),
		[act]
	);

	return item.award_url ? (
		<a
			target='_blank'
			href={item.award_url}
			rel='noopener noreferrer'
			onMouseEnter={() => setAct(true)}
			onMouseLeave={() => setAct(false)}
			className={'awardsItemColumn-white'}>
			{elementInner}
		</a>
	) : (
		<div
			onMouseEnter={() => setAct(true)}
			onMouseLeave={() => setAct(false)}
			className={'awardsItemColumn-white'}>
			{elementInner}
		</div>
	);
});

export default AwardsWhite;
