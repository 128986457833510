import { memo as Memo, useRef, useEffect, useCallback, useMemo } from 'react';
import { TweenMax } from 'gsap';

//* SVGs
import { AwardsItem1, AwardsItem2, AwardsItem3, AwardsItem4 } from '../AwardsSvg';

const AwardsBlack = Memo(({ ind, anime, ...item }) => {
	//! Refs
	const cardRef = useRef();
	const scaleRef = useRef();
	const textRef = useRef();

	//! Static Variables
	const time = useMemo(() => [0.5, 0.7, 0.8, 0.9], []);

	useEffect(() => {
		let scale1 = 0,
			borderWidth = 400,
			scale2 = 2.1;

		if (anime) {
			scale1 = 1;
			borderWidth = 6;
			scale2 = 1;
		}

		TweenMax.staggerTo(
			scaleRef.current,
			time[ind],
			{
				scale: scale1,
			},
			time[ind]
		);
		TweenMax.staggerTo(
			scaleRef.current.children[0],
			time[ind],
			{
				borderWidth: borderWidth,
			},
			time[ind]
		);
		TweenMax.staggerTo(
			scaleRef.current.children[1],
			time[ind],
			{
				scale: scale2,
			},
			time[ind]
		);
	}, [anime, ind]);

	//! Mouse Over
	const mouseOver = useCallback(
		(e) => {
			const obj = e.target?.getBoundingClientRect();
			const relX = e.pageX - obj.left;

			TweenMax.to(
				cardRef.current,
				1,
				{
					x: ((relX - obj.width / 2) / obj.width) * -90,
					rotation: 3,
				},
				0.6
			);

			TweenMax.to(
				textRef.current,
				1,
				{
					x: ((relX - obj.width / 2) / obj.width) * 60,
				},
				0.8
			);
		},
		[cardRef, textRef]
	);

	//! Mouse Out
	const mouseOut = useCallback(() => {
		TweenMax.to(
			cardRef.current,
			1,
			{
				x: 0,
				rotation: 0,
			},
			0.8
		);
		TweenMax.to(
			textRef.current,
			1,
			{
				x: 0,
			},
			0.8
		);
	}, [cardRef, textRef]);

	//! SVG Element
	const SVGEl = useMemo(() => {
		const index = ind + 1;
		return index === 1 ? AwardsItem1 : index === 2 ? AwardsItem2 : index === 3 ? AwardsItem3 : AwardsItem4;
	}, [ind]);

	return (
		<a
			className={`crCircle awardsItemColumn-black`}
			href={item.award_url}
			target='_blank'
			rel='noopener noreferrer'
			ref={scaleRef}>
			<div className={'border'}></div>

			<div
				onMouseOut={mouseOut}
				onMouseMove={mouseOver}
				className={'awardsItemCont'}>
				<div
					ref={cardRef}
					className={'awardsItemIcon'}>
					<SVGEl />
				</div>

				<div
					ref={textRef}
					className={'awardsItemInfo'}>
					<p className={`h6 font-poppins font-medium awardName`}>
						{item.title}&nbsp;{item.year}
					</p>

					<p className={`p-m font-anonymous font-regular awardsItemDescription`}>{item.description}/</p>

					<p className={'p-m font-poppins font-italic'}>{item.project_url.replace(/(^\w+:|^)\/\//, '')}</p>
				</div>
			</div>
		</a>
	);
});

export default AwardsBlack;
