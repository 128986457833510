import { memo as Memo, useMemo, useEffect, useState, useRef } from 'react';
import { useParams } from 'next/navigation';
import { gsap, CSSPlugin } from 'gsap';
import { orderBy } from 'lodash';

gsap.registerPlugin(CSSPlugin);

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Components
import AwardsBlack from './AwardsBlack';
import AwardsWhite from './AwardsWhite';

//* Styles
import AwardsStyle from './style';

const Awards = Memo(({ anime, count, type, active, className, globalData }) => {
    //! Params
    const params = useParams();

    //! States
    const [data, setData] = useState();

    //! Refs
    const topRef = useRef();

    useEffect(() => setData(globalData), [globalData, params.locale]);

    const countVar = useMemo(() => (!count ? data?.awards.length : 4), [count, data]);

    const itemsArr = useMemo(() => data && orderBy(data.awards, ['year'], ['desc']), [data]);

    const items = useMemo(() => {
        return (
            itemsArr &&
            itemsArr.map((item, ind) => {
                return (
                    ind < countVar &&
                    (type !== 1 ? (
                        <AwardsBlack
                            key={ind}
                            ind={ind}
                            anime={anime}
                            {...item}
                        />
                    ) : (
                        <AwardsWhite
                            key={ind}
                            {...item}
                        />
                    ))
                );
            })
        );
    }, [itemsArr, anime, countVar]);

    //! Animation
    const tl = useMemo(() => new gsap.timeline({ delay: 0.1 }), []);

    useEffect(() => {
        if (active) {
            tl.staggerTo(
                [topRef.current.childNodes],
                0,
                {
                    css: {
                        opacity: 1,
                    },
                },
                0.1
            );
        } else {
            tl.staggerTo(
                [topRef.current.childNodes],
                0,
                {
                    css: {
                        opacity: 0,
                    },
                    delay: 0,
                },
                0
            );
        }

        return () => {
            tl.clear();
        };
    }, [active]);

    return (
        <AwardsStyle
            ref={topRef}
            className={`awardsItemWrap ${className || ''} ${type !== 1 ? 'awardsItemBlack' : ''}`}>
            {items}
        </AwardsStyle>
    );
});

export default withUIContext(Awards, ['globalData']);
