import styled from 'styled-components';

const CursorParticlesStyle = styled.div`
	&.cursorParticles {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		pointer-events: none;

		.canvas {
			width: 100%;
			height: 100%;
		}
	}
`;

export default CursorParticlesStyle;
