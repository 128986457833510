import { memo as Memo, useMemo, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/dist/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const ArrowBtnLink = Memo(({ animate }) => {
	//! Refs
	const arrLeftRef = useRef();
	const arrLeftRefPaph = useRef();

	//! Animation
	const tl = useMemo(() => gsap.timeline({ pause: true }), []);

	useEffect(() => {
		tl.to(arrLeftRef.current, {
			duration: 1,
			repeat: -1,
			repeatDelay: 0,
			yoyo: true,
			ease: 'power1.inOut',
			motionPath: {
				path: arrLeftRefPaph.current,
				align: arrLeftRefPaph.current,
				autoRotate: 160,
				alignOrigin: [0.5, 0.1],
			},
		});

		animate ? tl.play() : tl.kill();
	}, [animate]);

	return (
		<svg
			preserveAspectRatio='none'
			viewBox='0 0 280 98.5'>
			<path
				ref={arrLeftRef}
				d='M270.7,34.8v48.6c-19.1-23.7-42.5-43.6-69-58.6C174,9.4,144.7,1,114.7,0.2C77.3-1.1,38.6,9.4,0,31l4.6,8.2
	        C71.8,1.4,136.5-0.7,196.7,32.8c34.2,19,57.2,44.8,66.6,56.4h-47.6v9.3H280V34.8H270.7z'
			/>
			<path
				ref={arrLeftRefPaph}
				stroke='none'
				fill='none'
				d='M186.5,22.9c-7.8-4.3-17.6-8.5-28.8-11.7c-15.7-4.5-29.7-6-40.8-6.4'
			/>
		</svg>
	);
});

export default ArrowBtnLink;
